import Header from '../../components/Header/Header';
import './Connect.scss'
import Footer from '../../components/Footer/Footer';
import { useState, useEffect } from 'react';

const Connect = () => {
    const [scrollingDisabled, setScrollingDisabled] = useState(false);

    // remove scrolling
    useEffect(() => {
        window.innerWidth > 844 && setScrollingDisabled(true)
        document.body.style.overflow = scrollingDisabled ? 'hidden' : 'auto';
    }, [scrollingDisabled]);
    return (
        <div className='connect'>
            <Header/>
            <div className='connect__components'>
            <div className='connect__form'>
                <h1 className='connect__title'>CONNECT</h1>
                <div className='connect__input-div'>
                    <input name='name' id='name' required className='connect__input'/>
                    <label htmlFor='name' className='connect__label'>NAME:</label>
                </div>
                <div className='connect__input-div connect__input-div--select'>
                    <label htmlFor='budget' className='connect__label connect__label--select'>BUDGET:</label>
                    <select name='budget' id='budget' className='connect__input connect__input--select'>
                        <option className='connect__option' value='$1,000 &mdash; $5,000'>$1,000 &mdash; $5,000</option>
                        <option className='connect__option' value='$1,000 &mdash; $10,000'>$5,000 &mdash; $10,000</option>
                        <option className='connect__option' value='over $10,000'>over $10,000</option>
                    </select>
                </div>
                <div className='connect__input-div'>
                    <input name='email' id='email' required className='connect__input' type='email'/>
                    <label htmlFor='email' className='connect__label'>EMAIL:</label>
                </div>
                <div className='connect__input-div'>
                    <textarea name='message' id='message' required className='connect__input connect__input--textarea' rows={6}></textarea>
                    <label htmlFor='message' className='connect__label'>MESSAGE:</label>
                </div>
                <button className='connect__button connect__cta'>CONNECT</button>
            </div>
            <div className='connect__thankyou-div'>
                <h2 className='connect__h'>Express web design $1000</h2>
                <p className='connect__p'>You provide us with required inputs: commercial phone number, emial. images, and any other useful data. After 7 days of our work we send you a perfectly designed, hosted version of a website, that accepts email forms, payments, and has a unique domain name. </p>
                <h2 className='connect__h'>Express mobile app $5000</h2>
                <p className='connect__p'>You provide us with required inputs: commercial phone number, emial. images, and any other useful data. After 7 days of our work we send you a perfectly designed mobile app, hosted on Play Market and App Store, that has a payed subscription and a unique domain name. </p>
                <h2 className='connect__h'>Bespoken projects start at $10,000</h2>
                <p className='connect__p'>We schedule a call, carefully listen to all of your wishes, then send you the estimate <b>before each step</b>. Steps include a website or app navigation, wireframe, prototype, developed version, and your corrections in-between of each step.</p>

                {/* <img className='connect__thankyou' src={require('../../assets/images/heart.png')} alt='heart'/> */}
            </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Connect;