import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import './Experience.scss'
import { LuExternalLink } from "react-icons/lu";
import { useState, useEffect } from 'react';

const Experience = () => {

    const [scrollingDisabled, setScrollingDisabled] = useState(false);

    // remove scrolling for wide-desktop
    useEffect(() => {
        window.innerWidth > 844 && setScrollingDisabled(true)
        document.body.style.overflow = scrollingDisabled ? 'hidden' : 'auto';
    }, [scrollingDisabled]);

    return (
        <div className='exp'>
            <Header/>
            <div className='exp__page'>
            <div className='exp__top'>
                <h1 className='exp__heading'>WORK</h1>
                <p className='exp__description'>We are proud to share a few recent projects that we are most proud of. Your project will be published on a website contingent on negotiation and formalizing it in a contract.</p>
            </div>
            <div className='exp__projects'>
                <a href='https://magenta-toffee-1e4e9d.netlify.app' className='exp__project' target='_blank'>
                    <img className='exp__image' src={require('../../assets/images/dojodream.png')}/>
                    <div className='exp__content'>
                        <h2 className='exp__project-name'>dojodream <LuExternalLink/></h2>
                        <p className='exp__project-description'>
                            We built a mobile app that allows anybody to find martial arts gyms and connect with them.
                        </p>
                    </div>
                </a>
                <a href='https://optimumrooffl.com' className='exp__project' target='_blank'>
                    <img className='exp__image' src={require('../../assets/images/optimum-roofing.png')}/>
                    <div className='exp__content'>
                        <h2 className='exp__project-name'>Optimum Roofing <LuExternalLink/></h2>
                        <p className='exp__project-description'>
                            Optimum Roofing builds luxury metal roofs. DOJOTECH builds luxury websites. We decided to work together...
                        </p>
                    </div>
                </a>
            </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Experience; 