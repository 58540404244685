import { useState } from 'react';
import './Header.scss';

const Header = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const makeActive = () => {
        isMenuOpen ? setIsMenuOpen(false) : setIsMenuOpen(true);
        // const hamburger = document.querySelector('.header__hamburger')
        // hamburger.classList.toggle('header__hamburger--active');
    };

    return (
        <header className='header'>
            <div className='header__top'>
                {/* <img onClick={navigate('/')} className='header__logo' alt='logo' src={require('../../assets/images/headway.png')}/> */}
                <a className='header__logo' href='/'>DOJOTECH</a>
                <button className={`header__hamburger ${isMenuOpen? `header__hamburger--active`: ''}`} onClick={makeActive}>
                    <div className='header__bar'></div>
                </button>
            </div>
            <ul className={`header__menu ${isMenuOpen? `header__menu--open`: ''}`}>
                <li className='header__li'><a href='/about' className='header__link'>ABOUT</a></li>
                <li className='header__li'><a href='/experience' className='header__link'>WORK</a></li>
                <li className='header__li'><a href='/connect' className='header__link'>CONNECT</a></li>
            </ul>
        </header>
    );
}

export default Header;