import "./Footer.scss";
import { FaInstagramSquare, FaLinkedin, FaFacebookSquare } from "react-icons/fa";
import { FaSquareXTwitter  } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__contact">
        <ul className="footer__media">
          <li className="footer__media-li">
            <a href="#" className="footer__media-link">
              <FaInstagramSquare className="footer__icon" />
            </a>
          </li>
          <li className="footer__media-li">
            <a href="#" className="footer__media-link">
              <FaLinkedin className="footer__icon" />
            </a>
          </li>
          <li className="footer__media-li">
            <a href="#" className="footer__media-link">
              <FaSquareXTwitter className="footer__icon" />
            </a>
          </li>
          <li className="footer__media-li">
            <a href="#" className="footer__media-link">
              <FaFacebookSquare className="footer__icon" />
            </a>
          </li>
        </ul>
      </div>
      <div className="footer__contact">
        <p className="footer__string">+1 &#x28;561&#x29; 877 0410</p>
      </div>
      <div className="footer__contact">
        <p className="footer__string">dojotech@gmail.com</p>
      </div>
    </footer>
  );
};

export default Footer;
