import './Home.scss'
import Marquee from 'react-fast-marquee';
import { useState, useEffect } from 'react';

const Home = () => {

    const [scrollingDisabled, setScrollingDisabled] = useState(false);

    // remove scrolling
    useEffect(() => {
        setScrollingDisabled(true)
        document.body.style.overflow = scrollingDisabled ? 'hidden' : 'auto';
    }, [scrollingDisabled]);
  

    return (
        <div className='home'>
                <div className='home__running-text-container'>
                    <Marquee speed={50}>
                        <div className='home__running-text'>
                            <span>WE ARE DOJOTECH</span>
                        </div>  
                        <div className='home__running-text'>
                            <span>×</span>
                        </div>  
                        <div className='home__running-text'>
                            <span>WE MAKE YOUR BRAND DIGITAL AND FUTURISTIC</span>
                        </div>  
                        <div className='home__running-text'>
                            <span>×</span>
                        </div> 
                        <div className='home__running-text'>
                            <span>WE BUILD COONECTION BETWEEN BUSINESS AND CUSTOMER</span>
                        </div>  
                        <div className='home__running-text'>
                            <span>×</span>
                        </div>
                        <div className='home__running-text'>
                            <span>WE INSPIRE YOUR CLIENTS TO MAKE A PURCHASE</span>
                        </div>  
                        <div className='home__running-text'>
                            <span>×</span>
                        </div>
                        <div className='home__running-text'>
                            <span>WE PRIORITIZE CUSTOMERS WHO WORK ON IMPROVEMENT OF THEIR COMMUNITIES</span>
                        </div>  
                        <div className='home__running-text'>
                            <span>×</span>
                        </div>
                    </Marquee>
                </div>
                <div className='home__section'>
                    <div className='home__image-div'>
                    <img alt='logo of dojotech' className='home__image' src={require('../../assets/images/headway.png')}/>
                    </div>
                    <a href='/about' className='home__section-text'>
                        ABOUT
                    </a>
                    <a href='/experience' className='home__section-text'>
                        WORK
                    </a>
                    <a href='/connect' className='home__section-text'>
                        CONNECT
                    </a>
                </div>
                <div className='home__running-text-container'>
                    <Marquee speed={50} direction='reverse'> 
                        <div className='home__running-text'>
                            <span>EXPRESS WEB DEVELOPMENT</span>
                        </div>  
                        <div className='home__running-text'>
                            <span>×</span>
                        </div>  
                        <div className='home__running-text'>
                            <span>MOBILE APP DEVELOPMENT</span>
                        </div>  
                        <div className='home__running-text'>
                            <span>×</span>
                        </div> 
                        <div className='home__running-text'>
                            <span>EXPRESS WEB DESIGN</span>
                        </div>  
                        <div className='home__running-text'>
                            <span>×</span>
                        </div>
                        <div className='home__running-text'>
                            <span>SOCIAL MEDIA MARKETING</span>
                        </div>  
                        <div className='home__running-text'>
                            <span>×</span>
                        </div> 
                        <div className='home__running-text'>
                            <span>TARGETING</span>
                        </div>  
                        <div className='home__running-text'>
                            <span>×</span>
                        </div> 
                        <div className='home__running-text'>
                            <span>CONTENT MARKETING</span>
                        </div>  
                        <div className='home__running-text'>
                            <span>×</span>
                        </div> 
                        <div className='home__running-text'>
                            <span>EMAIL MARKETING</span>
                        </div>  
                        <div className='home__running-text'>
                            <span>×</span>
                        </div> 
                        <div className='home__running-text'>
                            <span>MOBILE APP DESIGN</span>
                        </div>  
                        <div className='home__running-text'>
                            <span>×</span>
                        </div> 
                        <div className='home__running-text'>
                            <span>GOOGLE ADS</span>
                        </div>  
                        <div className='home__running-text'>
                            <span>×</span>
                        </div>
                        <div className='home__running-text'>
                            <span>DIGITAL PRODUCT DEVELOPMENT</span>
                        </div>  
                        <div className='home__running-text'>
                            <span>×</span>
                        </div>
                    </Marquee>
                </div>
        </div>  
    );
}

export default Home;