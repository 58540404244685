import Header from '../../components/Header/Header';
import './About.scss'
import { IoShareSocialOutline } from "react-icons/io5";
import { CgWebsite } from "react-icons/cg";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { HiOutlineSearch } from "react-icons/hi";
import { RiArticleLine } from "react-icons/ri";
import { useState, useEffect } from 'react';
import Footer from '../../components/Footer/Footer';


const About = () => {

    const [visibleSerge, setVisibleSerge] = useState(false)
    const [visibleTai, setVisibleTai] = useState(false)
    const [visibleValer, setVisibleValer] = useState(false)

    const makeVisibleSerge = () => {
        visibleSerge ? setVisibleSerge(false) : setVisibleSerge(true);
        
    }

    const makeVisibleTai = () => {
        visibleTai ? setVisibleTai(false) : setVisibleTai(true);
    }

    const makeVisibleValer = () => {
        visibleValer ? setVisibleValer(false) : setVisibleValer(true);
    }

    const [scrollingDisabled, setScrollingDisabled] = useState(false);

    // remove scrolling for wide-desktop
    useEffect(() => {
        window.innerWidth > 844 && setScrollingDisabled(true)
        document.body.style.overflow = scrollingDisabled ? 'hidden' : 'auto';
    }, [scrollingDisabled]);
 
    return (
    <div className='about'>
        <Header/>
        <div className='about__page'>
            <div className='about__top'>
                <div className='about__content-top'>
                    <h1 className='about__heading'>ABOUT</h1>
                    <p className='about__description'>We help businesses get customers from a digital world. We design and build websites and mobile apps; set up marketing campaings so people would buy from you! At the same time we grow your digital brand, that customers want to return to.</p>
                </div>
                
            </div>
            <div className='about__section-workers'>
                <div className='about__member'>
                    <div className={`about__image-div ${visibleSerge ? 'about__image-div--active' : ''}`} onClick={makeVisibleSerge}>
                        <img className='about__image' src={require('../../assets/images/headway.png')} alt='Serge Yegorian - founder of DOJOTECH'/>
                        <p className={`about__image-description ${visibleSerge ? 'about__image-description--visible' : ''}`}>Serge, starting as a freelance developer, grew into the founder of his digital agency. Recognizing that development skills alone weren't enough, he mastered UI/UX design and marketing for a holistic approach. The lingering question—How much can one accomplish solo?—marked the inception of DOJOTECH.<br/> <span className='about__break'>Serge will be developing your website and mobile application.</span></p>
                    </div>
                    <div className='about__content'>
                        <h2 className='about__member-name'>Serge Yegorian</h2>
                        <p className='about__member-description'>DEVELOPER / FOUNDER</p>
                    </div>
                </div>
                <div className='about__member'>
                    <div className={`about__image-div ${visibleTai ? 'about__image-div--active' : ''}`} onClick={makeVisibleTai}>
                        <img className='about__image' src={require('../../assets/images/headway.png')} alt='Serge Yegorian - founder of DOJOTECH'/>
                        <p className={`about__image-description ${visibleTai ? 'about__image-description--visible' : ''}`}>Tai, with extensive experience as a web and graphic designer, has dedicated her skills to churches, charity funds, and nonprofits. Her primary goal is to narrate a compelling story through design, leading users as if they were the protagonists in their own tales.<br/><span className='about__break'>Tai will be creating graphics and designing your product.</span></p>
                    </div>
                    <div className='about__content'>
                        <h2 className='about__member-name'>Tai Ohio</h2>
                        <p className='about__member-description'>LEAD DESIGNER</p>
                    </div>
                </div>
                <div className='about__member'>
                    <div className={`about__image-div ${visibleValer ? 'about__image-div--active' : ''}`} onClick={makeVisibleValer}>
                        <img className='about__image' src={require('../../assets/images/headway.png')} alt='Serge Yegorian - founder of DOJOTECH'/>
                        <p className={`about__image-description ${visibleValer ? 'about__image-description--visible' : ''}`}>Valer is a content creator who interprets your brand message for customers, focusing on details to drive sales. With a track record of leading marketing campaigns for construction and healthcare companies, she has converted hundreds of people into clients.<br/><span className='about__break'>Valer will be marketing your product on social media and Google.</span></p>
                    </div>
                    <div className='about__content'>
                        <h2 className='about__member-name'>Valer Minor</h2>
                        <p className='about__member-description'>CONTENT CREATOR</p>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
    );
}

export default About;