import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import About from './pages/About/About';
import Connect from './pages/Connect/Connect';
import Experience from './pages/Experience/Exprerience';
import Home from './pages/Home/Home';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    const allElements = document.querySelectorAll('*');
    allElements.forEach((element) => {
      element.style.visibility = 'visible';
      element.style.opacity = '1';
    });
  }, []);

  return (
   <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/connect' element={<Connect/>}/>
      <Route path='/experience' element={<Experience/>}/>
    </Routes>
   </BrowserRouter>
  );
}

export default App;
